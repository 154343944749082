/* Font Family */
/* Borda arredondada */
body > #root #modalBox.mcceeercap > .modalBody {
  max-width: 700px !important;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form .MuiFormControl-root {
  visibility: hidden;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form .MuiFormControl-root,
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form .MuiFormControl-root * {
  height: 1px;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > div.date {
  width: 350px;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > div > input,
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > div > select {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
  margin-left: 15px;
  padding: 10px 15px;
  width: 100px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #8c8c8c;
  border: 1px solid #808080;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button:not(:disabled) {
  cursor: pointer;
  background-color: #006700;
  border: 1px solid #004d00;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button:not(:disabled).sending {
  color: #FFF;
  cursor: default;
  background-color: #e37d1d;
  border-color: #e58a34;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button:not(:disabled).sending > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > div > .buttons > button:not(:disabled):not(.sending):hover {
  color: #FFF;
  background-color: #003400;
  border-color: #001a00;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .sending {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 19px;
  font-size: 0.9em;
  color: #156815;
  background-color: #d3f6d3;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .sending {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .sending > b {
  margin: 0 5px;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > hr {
  margin: 25px 0 !important;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 70px;
  margin-bottom: 3px;
  padding: 10px;
  width: 100%;
  font-size: 0.8em;
  color: #FFF;
  background-color: #a81d11;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .error {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: -30px;
  height: 309px;
  color: #EA4335;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .alert {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: -30px;
  height: 309px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .loading {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .loading.sent > .icon {
  height: 220px !important;
}
body > #root #modalBox.mcceeercap > .modalBody > .modalContent > form > .loading > .icon {
  height: 100px;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
