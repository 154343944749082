/* Font Family */
/* Borda arredondada */
body > #root > .container > main.adm_permissions > .permissionContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: fixed;
  top: 75px;
  left: 300px;
  right: 0;
  margin: 0 !important;
  padding: 20px;
  background-color: #f5f5f5;
  z-index: 99;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: auto;
  width: 35%;
  overflow: hidden;
  color: #666;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 5px 10px;
  height: 100%;
  font-size: 0.9rem;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > #search {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > .btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  width: 40px;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > .btn {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > .btn.lnk {
  cursor: pointer;
}
body > #root > .container > main.adm_permissions > .permissionContent > .header > .searchBox > .btn.lnk:hover {
  color: #FFF;
  background-color: #bf2114;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header {
    top: 50px;
    left: 200px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header {
    top: 55px;
    left: 225px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header {
    top: 60px;
    left: 250px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .header {
    top: 65px;
    left: 275px;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .users {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-top: 60px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_permissions > .permissionContent > .users {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_permissions > .permissionContent > .users.loading {
  min-height: 100%;
}
body > #root > .container > main.adm_permissions > .permissionContent > .users.loading > .loading-single {
  padding: 25px 0 30px;
  height: 100%;
}
