/* Font Family */
/* Borda arredondada */
.react-calendar__tile {
  border: solid 2px #FFF !important;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
}
.react-calendar__tile:hover {
  background-color: none !important;
}
body > #root > .container > main .calendarBox {
  padding: 0px 5px 15px 5px;
  background-color: #FFF;
  border: solid 1px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > .container > main .calendarBox div * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main .calendarBox div > .boxInfoCalendar {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar > .btnExcel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 12px;
  padding: 0px;
  font-size: 0.7em;
  color: #065F46;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main .calendarBox div > .boxInfoCalendar > .btnExcel {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar > .btnExcel:hover {
  color: #032f23;
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar > .btnExcel > span {
  margin-left: 5px;
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar > p {
  margin: 10px 0 5px;
  width: inherit;
  font-weight: bold;
  font-size: 1.4em;
  text-align: center;
}
body > #root > .container > main .calendarBox div > .boxInfoCalendar > hr {
  margin: 0 0 10px 0;
}
body > #root > .container > main .calendarBox > .react-calendar {
  width: 100%;
  border: none !important;
}
body > #root > .container > main .calendarBox .react-calendar .hif,
body > #root > .container > main .calendarBox .react-calendar .he,
body > #root > .container > main .calendarBox .react-calendar .hcc,
body > #root > .container > main .calendarBox .react-calendar .dis {
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main .calendarBox .react-calendar .hif {
  background-color: #FFE6E6;
}
body > #root > .container > main .calendarBox .react-calendar .hif:hover {
  background-color: #ffcccc !important;
}
body > #root > .container > main .calendarBox .react-calendar .he {
  background-color: #f8f0d9;
}
body > #root > .container > main .calendarBox .react-calendar .he:hover {
  background-color: #f4e8c3 !important;
}
body > #root > .container > main .calendarBox .react-calendar .hcc {
  background-color: #ddf8e4;
}
body > #root > .container > main .calendarBox .react-calendar .hcc:hover {
  background-color: #c8f4d3 !important;
}
body > #root > .container > main .calendarBox .react-calendar .dis {
  cursor: default;
  background-color: #F0F0F0;
}
body > #root > #modalBox .modalBody > .modalContent > .title,
body > #root > #modalBox .modalBody > .modalContent > .consBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 4px 0;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox .modalBody > .modalContent > .title,
  body > #root > #modalBox .modalBody > .modalContent > .consBox {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox .modalBody > .modalContent > .title > span,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox .modalBody > .modalContent > .title > span,
  body > #root > #modalBox .modalBody > .modalContent > .consBox > span {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox .modalBody > .modalContent > .title > span:first-child,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span:first-child {
  width: 25%;
}
body > #root > #modalBox .modalBody > .modalContent > .title > span:last-child,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span:last-child {
  width: 15%;
}
body > #root > #modalBox .modalBody > .modalContent > .title > span.status,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span.status {
  width: 60%;
}
body > #root > #modalBox .modalBody > .modalContent > .title > span.red,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span.red {
  color: #EA4335;
}
body > #root > #modalBox .modalBody > .modalContent > .title > span.yellow,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span.yellow {
  color: #FBBC04;
}
body > #root > #modalBox .modalBody > .modalContent > .title > span.green,
body > #root > #modalBox .modalBody > .modalContent > .consBox > span.green {
  color: #22A822;
}
body > #root > #modalBox .modalBody > .modalContent > .title {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
}
