/* Font Family */
/* Borda arredondada */
body > #root .docs > hr {
  margin: 8px 0 10px;
  height: 5px;
  background-color: #E0E0E0;
}
body > #root .docs > .docBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 3px;
  width: 100%;
  height: 50px;
  color: #999;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .docs > .docBox {
    flex: 0 0 auto !important;
  }
}
body > #root .docs > .docBox:hover,
body > #root .docs > .docBox.active {
  color: #666;
  background-color: #FFF;
}
body > #root .docs > .docBox:hover > .icon,
body > #root .docs > .docBox.active > .icon {
  background-color: #e3e3e3;
}
body > #root .docs > .docBox.folder > .status {
  background-color: #e69500;
}
body > #root .docs > .docBox > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .docs > .docBox > * {
    flex: 0 0 auto !important;
  }
}
body > #root .docs > .docBox > .status {
  width: 8px;
  height: 100%;
  background-color: #8DAECA;
}
body > #root .docs > .docBox > .status.on {
  background-color: #16a016;
}
body > #root .docs > .docBox > .status.off {
  background-color: #a01616;
}
body > #root .docs > .docBox > .icon {
  margin-left: 3px;
  padding: 10px 15px 10px 13px;
  height: 100%;
  background-color: #F0F0F0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root .docs > .docBox > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-left: 3px;
  padding: 10px 15px;
  height: 100%;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  body > #root .docs > .docBox > .name {
    flex: 0 0 auto !important;
  }
}
body > #root .docs > .docBox > .size {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 2px 3px 0 0;
  padding: 10px 15px;
  width: 120px;
  max-width: 120px;
  height: 94%;
  font-size: 0.8em;
  border-left: 2px solid #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root .docs > .docBox > .size {
    flex: 0 0 auto !important;
  }
}
body > #root .docs > .docBox > a,
body > #root .docs > .docBox button {
  margin-left: 1px;
  padding: 10px 15px;
  height: 100%;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root .docs > .docBox > a.load,
body > #root .docs > .docBox button.load {
  background-color: #e37d1d;
}
body > #root .docs > .docBox > a.load > svg,
body > #root .docs > .docBox button.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root .docs > .docBox > a.download,
body > #root .docs > .docBox button.download {
  background-color: #69a671;
}
body > #root .docs > .docBox > a.download:hover,
body > #root .docs > .docBox button.download:hover {
  background-color: #518051;
}
body > #root .docs > .docBox > a.open,
body > #root .docs > .docBox button.open,
body > #root .docs > .docBox > a.close,
body > #root .docs > .docBox button.close {
  cursor: pointer;
  background-color: #797979;
}
body > #root .docs > .docBox > a.open:hover,
body > #root .docs > .docBox button.open:hover,
body > #root .docs > .docBox > a.close:hover,
body > #root .docs > .docBox button.close:hover {
  background-color: #555555;
}
body > #root .docs > .docBox > a:hover > svg,
body > #root .docs > .docBox button:hover > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root .docs > .docBox > a > svg,
body > #root .docs > .docBox button > svg {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .docs > .docBox > .status {
    width: 5px;
  }
  body > #root .docs > .docBox > .icon {
    margin-left: 1px;
    padding: 5px 13px;
  }
  body > #root .docs > .docBox > .name {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 0.9em;
  }
  body > #root .docs > .docBox > .size {
    display: none !important;
  }
  body > #root .docs > .docBox > a,
  body > #root .docs > .docBox button {
    padding: 5px 13px;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root .docs > .docBox > .name {
    flex: 0 0 auto !important;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
