/* Font Family */
/* Borda arredondada */
body > #root #modalBox.mpermissions > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .header {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .header > .tab {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 15px 20px;
  width: 50%;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .header > .tab {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .header > .tab.active {
  color: #FFF;
  background-color: #977102;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > select,
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  width: 100%;
  background-color: #F0F0F0;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > select,
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > select {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 25px;
  border-bottom: 3px solid #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 15px;
  padding: 15px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  background-color: #F0F0F0;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > button {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > button:disabled {
  cursor: default;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .unitLine > button:not(:disabled):hover {
  color: #FFF;
  background-color: #22A822;
  border-color: #197e19;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units > .lineUnit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units > .lineUnit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units > .lineUnit:not(:first-child) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #F0F0F0;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units > .lineUnit > span {
  font-size: 0.9em;
  color: #666;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > .permissionBox > .units > .lineUnit > button {
  cursor: pointer;
  color: #EA4335;
  background-color: transparent;
  border: none;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 30px -15px -15px;
  padding: 15px;
  background-color: #e3e3e3;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer > button {
  padding: 10px 15px;
  color: #FFF;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer > button#btnSave {
  background-color: #197e19;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer > button#btnSave:hover {
  background-color: #115311;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer > button#btnCancel {
  background-color: #8c8c8c;
}
body > #root #modalBox.mpermissions > .modalBody > .modalContent > footer > button#btnCancel:hover {
  background-color: #737373;
}
