/* Font Family */
/* Borda arredondada */
body > #root .mailersBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 11px;
  color: #333;
}
@media screen and (max-width: 767px) {
  body > #root .mailersBox {
    flex: 0 0 auto !important;
  }
}
body > #root .mailersBox > .header {
  margin: 0 !important;
}
body > #root .mailersBox > .header > * {
  font-weight: bold;
  background-color: #D0D0D0 !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root .mailersBox > .header > .scroll {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20px;
  background-color: #D0D0D0 !important;
}
@media screen and (max-width: 767px) {
  body > #root .mailersBox > .header > .scroll {
    flex: 0 0 auto !important;
  }
}
body > #root .mailersBox > .mailList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  height: 245px;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  body > #root .mailersBox > .mailList {
    flex: 0 0 auto !important;
  }
}
body > #root .mailersBox > .header,
body > #root .mailersBox > .mailList > .mailer_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 2px 0 0;
}
@media screen and (max-width: 767px) {
  body > #root .mailersBox > .header,
  body > #root .mailersBox > .mailList > .mailer_line {
    flex: 0 0 auto !important;
  }
}
body > #root .mailersBox > .header > .sel,
body > #root .mailersBox > .mailList > .mailer_line > .sel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  width: 6%;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root .mailersBox > .header > .sel,
  body > #root .mailersBox > .mailList > .mailer_line > .sel {
    flex: 0 0 auto !important;
  }
}
body > #root .mailersBox > .header > .sel > input,
body > #root .mailersBox > .mailList > .mailer_line > .sel > input {
  margin: 0;
}
body > #root .mailersBox > .header > .unit,
body > #root .mailersBox > .mailList > .mailer_line > .unit {
  margin-left: 2px;
  padding: 10px;
  display: inline-block;
  width: 94%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
