/* Font Family */
/* Borda arredondada */
body > #root #modalBox.maddmailers > .modalBody {
  max-width: 1200px !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent {
  max-width: 1200px !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header {
  margin: 0 !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > * {
  font-weight: bold;
  background-color: #D0D0D0 !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  height: 306px;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 1px 0 0;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 88%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .name,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .name,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .mail,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .mail,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .unit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .unit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .name,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .name,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .mail,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .mail,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .unit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .unit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_ercap {
  display: inline-block;
  padding: 10px;
  width: 20%;
  height: 33px;
  white-space: nowrap;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .name:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .name:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .mail:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .mail:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .unit:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .unit:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .report:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .report:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .sales_off:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .sales_off:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .bkp_energy:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .bkp_energy:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .ccee_fees:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .ccee_fees:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos .ccee_ercap:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos .ccee_ercap:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .name:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .name:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .mail:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .mail:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .unit:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .unit:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .report:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .report:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .sales_off:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .sales_off:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .bkp_energy:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .bkp_energy:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_fees:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_fees:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_ercap:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_ercap:not(:first-child) {
  margin-left: 2px;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item > .ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item > .ccee_ercap {
  width: 10.87%;
  text-align: center;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .item,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .item {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit {
  display: none;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div {
  display: inline-block;
  padding: 2px;
  width: 20%;
  height: 33px;
  font-size: 11px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div.report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div.report,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div.sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div.sales_off,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div.bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div.bkp_energy,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div.ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div.ccee_fees,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div.ccee_ercap,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div.ccee_ercap {
  width: 11%;
  text-align: center;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div:not(:first-child),
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div:not(:first-child) {
  margin-left: 2px;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > .checkbox,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > .checkbox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 29px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > .checkbox,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > .checkbox {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > input,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > input,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > select,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 11px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > input,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > input,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > select,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > select {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > input.no-select,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > input.no-select,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > select.no-select,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > select.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > input.hidden,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > input.hidden,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .infos > .edit > div > select.hidden,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .infos > .edit > div > select.hidden {
  display: none !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 1px;
  width: 12%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0 10px;
  margin-left: 1px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #666;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:disabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:disabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:disabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #999 !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover {
  color: #FFF;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover:enabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover:enabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover:enabled,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover:enabled {
  cursor: pointer;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover.edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover.edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover.edit {
  background-color: #cc8400;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover.del,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover.del,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.del,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover.del {
  background-color: #cc0000;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover.save,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover.save,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.save,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover.save {
  background-color: #336900;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item > button:hover.cancel,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item > button:hover.cancel,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.cancel,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit > button:hover.cancel {
  background-color: #8f0048;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .item,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .item {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header > .buttons > .edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line > .buttons > .edit {
  display: none;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .infos > .item,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .infos > .item {
  display: none !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .infos > .edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .infos > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .infos > .edit,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .infos > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .buttons > .item,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .buttons > .item {
  display: none !important;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .buttons > .edit,
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .buttons > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .header.active > .buttons > .edit,
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .mailerList > .mailer_line.active > .buttons > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.add {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #b08403;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.add {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.add:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #977102;
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.save {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 2px;
  padding: 10px 15px;
  width: 15%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #156815;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.save {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.maddmailers > .modalBody > .modalContent > .footerBottons > button.save:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #115311;
}
