/* Font Family */
/* Borda arredondada */
.checkbox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  .checkbox {
    flex: 0 0 auto !important;
  }
}
.checkbox > * {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.checkbox > input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 0.2em solid currentColor;
  border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  -webkit-border-radius: 0.2em;
  background-color: #FFF;
}
.checkbox > input[type="checkbox"]:checked {
  display: grid;
  place-content: center;
}
.checkbox > input[type="checkbox"]:checked::before {
  transform: scale(0.6);
}
.checkbox > input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset 1em 1em #DB463B;
}
.checkbox > input[type="checkbox"].minor {
  width: 15px;
  height: 15px;
}
.checkbox > input[type="checkbox"].minor::before {
  width: 15px;
  height: 15px;
  box-shadow: inset 15px 15px #DB463B;
}
.checkbox.err input {
  border-color: #DF0D0D;
}
.checkbox > label {
  padding-left: 5px;
  font-size: 0.8rem;
}
