/* Font Family */
/* Borda arredondada */
body > #root .calculateBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative,
body > #root .calculateBox > .freeMarket {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative,
  body > #root .calculateBox > .freeMarket {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > h2,
body > #root .calculateBox > .freeMarket > h2 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px 20px;
  margin: 0;
  font-size: 18px;
  color: #FFF;
  background-color: #808080;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > h2,
  body > #root .calculateBox > .freeMarket > h2 {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > .body,
body > #root .calculateBox > .freeMarket > .body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body,
  body > #root .calculateBox > .freeMarket > .body {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > .body > .line,
body > #root .calculateBox > .freeMarket > .body > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 20px;
  color: #333;
  background-color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body > .line,
  body > #root .calculateBox > .freeMarket > .body > .line {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > .body > .line.economy,
body > #root .calculateBox > .freeMarket > .body > .line.economy {
  margin-top: 2px;
  padding: 20px 20px 0;
  background-color: #D6ECC6;
}
body > #root .calculateBox > .cative > .body > .line.economy:not(.perc),
body > #root .calculateBox > .freeMarket > .body > .line.economy:not(.perc) {
  margin-top: 10px;
}
body > #root .calculateBox > .cative > .body > .line.economy.neg,
body > #root .calculateBox > .freeMarket > .body > .line.economy.neg {
  background-color: #F8E0D0;
}
body > #root .calculateBox > .cative > .body > .line.total,
body > #root .calculateBox > .freeMarket > .body > .line.total,
body > #root .calculateBox > .cative > .body > .line.flag,
body > #root .calculateBox > .freeMarket > .body > .line.flag {
  padding: 20px 20px 0;
  color: #000;
  background-color: #e3e3e3;
}
body > #root .calculateBox > .cative > .body > .line.flag,
body > #root .calculateBox > .freeMarket > .body > .line.flag {
  background-color: #ebebeb;
}
body > #root .calculateBox > .cative > .body > .line.title,
body > #root .calculateBox > .freeMarket > .body > .line.title {
  padding: 15px 20px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #000;
}
body > #root .calculateBox > .cative > .body > .line.line-alert,
body > #root .calculateBox > .freeMarket > .body > .line.line-alert,
body > #root .calculateBox > .cative > .body > .line > div.alert,
body > #root .calculateBox > .freeMarket > .body > .line > div.alert {
  color: #EA4335;
}
body > #root .calculateBox > .cative > .body > .line > div,
body > #root .calculateBox > .freeMarket > .body > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 0 20px;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body > .line > div,
  body > #root .calculateBox > .freeMarket > .body > .line > div {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > .body > .line > div:not(:first-child),
body > #root .calculateBox > .freeMarket > .body > .line > div:not(:first-child) {
  width: 15%;
}
body > #root .calculateBox > .cative > .body > .line > div:first-child,
body > #root .calculateBox > .freeMarket > .body > .line > div:first-child {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body > .line > div:first-child,
  body > #root .calculateBox > .freeMarket > .body > .line > div:first-child {
    flex: 0 0 auto !important;
  }
}
body > #root .calculateBox > .cative > .body > .line > div.value.plus,
body > #root .calculateBox > .freeMarket > .body > .line > div.value.plus {
  width: 30%;
}
body > #root .calculateBox > .cative > .body > .line > div.value > span.mobile,
body > #root .calculateBox > .freeMarket > .body > .line > div.value > span.mobile {
  display: none;
}
body > #root .calculateBox > .cative sup,
body > #root .calculateBox > .freeMarket sup {
  margin-bottom: 4px;
  font-size: 0.6em;
}
body > #root .calculateBox > .freeMarket > h2 {
  background-color: #197e19;
}
body > #root .calculateBox > hr {
  background-color: #C0C0C0;
}
@media screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > h2,
  body > #root .calculateBox > .freeMarket > h2 {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding: 20px 15px;
    font-size: 14px;
  }
  body > #root .calculateBox > .cative > .body,
  body > #root .calculateBox > .freeMarket > .body {
    font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  }
  body > #root .calculateBox > .cative > .body > .line,
  body > #root .calculateBox > .freeMarket > .body > .line {
    padding: 0 15px;
    font-size: 10px;
  }
  body > #root .calculateBox > .cative > .body > .line.total,
  body > #root .calculateBox > .freeMarket > .body > .line.total,
  body > #root .calculateBox > .cative > .body > .line.flag,
  body > #root .calculateBox > .freeMarket > .body > .line.flag,
  body > #root .calculateBox > .cative > .body > .line.economy,
  body > #root .calculateBox > .freeMarket > .body > .line.economy {
    padding: 15px 15px 0;
  }
  body > #root .calculateBox > .cative > .body > .line.total > .qtty,
  body > #root .calculateBox > .freeMarket > .body > .line.total > .qtty,
  body > #root .calculateBox > .cative > .body > .line.flag > .qtty,
  body > #root .calculateBox > .freeMarket > .body > .line.flag > .qtty,
  body > #root .calculateBox > .cative > .body > .line.economy > .qtty,
  body > #root .calculateBox > .freeMarket > .body > .line.economy > .qtty,
  body > #root .calculateBox > .cative > .body > .line.total > .mult,
  body > #root .calculateBox > .freeMarket > .body > .line.total > .mult,
  body > #root .calculateBox > .cative > .body > .line.flag > .mult,
  body > #root .calculateBox > .freeMarket > .body > .line.flag > .mult,
  body > #root .calculateBox > .cative > .body > .line.economy > .mult,
  body > #root .calculateBox > .freeMarket > .body > .line.economy > .mult {
    display: none !important;
  }
  body > #root .calculateBox > .cative > .body > .line.title,
  body > #root .calculateBox > .freeMarket > .body > .line.title {
    padding: 15px 15px;
  }
  body > #root .calculateBox > .cative > .body > .line > div,
  body > #root .calculateBox > .freeMarket > .body > .line > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding: 0 0 15px;
  }
  body > #root .calculateBox > .cative > .body > .line > div.qtty,
  body > #root .calculateBox > .freeMarket > .body > .line > div.qtty,
  body > #root .calculateBox > .cative > .body > .line > div.value,
  body > #root .calculateBox > .freeMarket > .body > .line > div.value {
    width: 20%;
  }
  body > #root .calculateBox > .cative > .body > .line > div.mult,
  body > #root .calculateBox > .freeMarket > .body > .line > div.mult {
    width: 15%;
  }
  body > #root .calculateBox > .cative > .body > .line > div:first-child,
  body > #root .calculateBox > .freeMarket > .body > .line > div:first-child {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root .calculateBox > .cative > .body > .line > div.value > span,
  body > #root .calculateBox > .freeMarket > .body > .line > div.value > span {
    display: none;
  }
  body > #root .calculateBox > .cative > .body > .line > div.value > span.mobile,
  body > #root .calculateBox > .freeMarket > .body > .line > div.value > span.mobile {
    display: block;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > h2,
  body > #root .calculateBox > .freeMarket > h2 {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body > .line > div,
  body > #root .calculateBox > .freeMarket > .body > .line > div {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root .calculateBox > .cative > .body > .line > div:first-child,
  body > #root .calculateBox > .freeMarket > .body > .line > div:first-child {
    flex: 0 0 auto !important;
  }
}
