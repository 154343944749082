/* Font Family */
/* Borda arredondada */
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line:first-child {
  margin: 0;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 50px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > *:not(:first-child) {
  margin-left: 10px;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > *.center > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > *.center > label {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 4px;
  margin-bottom: 2px;
  width: 100%;
  font-size: 0.8em;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > label {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > label > span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > input,
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 11px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > input,
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > select {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > input.no-select,
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > select.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > input.hidden,
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > select.hidden {
  display: none !important;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > .checkbox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 29px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > .checkbox {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > .checkbox > input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .mailer_body > .line > * > .checkbox > input[type="checkbox"]:before {
  width: 15px;
  height: 15px;
  box-shadow: inset 15px 15px #db463b;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #D0D0D0;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.save {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 2px;
  padding: 10px 15px;
  width: 15%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #156815;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.save {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.save:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #115311;
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.cancel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #bf2114;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.cancel {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.meditmailers > .modalBody > .modalContent > .footerBottons > button.cancel:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #977102;
}
