/* Font Family */
/* Borda arredondada */
a#btns,
button#btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0.4rem 0.8rem;
  color: #666;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #999;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a#btns > *:not(:first-child),
button#btns > *:not(:first-child) {
  margin-left: 10px;
}
a#btns.box,
button#btns.box {
  border: none;
}
a#btns.box.save,
button#btns.box.save {
  color: #F0F0F0 !important;
  background-color: #197e19;
}
a#btns.box.edit,
button#btns.box.edit {
  color: #F0F0F0;
  background-color: #2d70b3;
}
a#btns.box.del,
button#btns.box.del,
a#btns.box.cancel,
button#btns.box.cancel {
  color: #F0F0F0;
  background-color: #ec584c;
}
a#btns:disabled,
button#btns:disabled {
  color: #a7a7a7;
  border-color: #c3c3c3;
  cursor: not-allowed;
}
a#btns:not(:disabled):hover:not(.box).save,
button#btns:not(:disabled):hover:not(.box).save,
a#btns:not(:disabled):hover:not(.box).down,
button#btns:not(:disabled):hover:not(.box).down {
  color: #197e19;
  border-color: #197e19;
}
a#btns:not(:disabled):hover:not(.box).edit,
button#btns:not(:disabled):hover:not(.box).edit,
a#btns:not(:disabled):hover:not(.box).check,
button#btns:not(:disabled):hover:not(.box).check {
  color: #2d70b3;
  border-color: #1d4a76;
}
a#btns:not(:disabled):hover:not(.box).del,
button#btns:not(:disabled):hover:not(.box).del,
a#btns:not(:disabled):hover:not(.box).cancel,
button#btns:not(:disabled):hover:not(.box).cancel {
  color: #ec584c;
  border-color: #d62516;
}
a#btns:not(:disabled):hover.box.save,
button#btns:not(:disabled):hover.box.save,
a#btns:not(:disabled):hover.box.down,
button#btns:not(:disabled):hover.box.down {
  color: #FFF;
  background-color: #0d3e0d;
}
a#btns:not(:disabled):hover.box.edit,
button#btns:not(:disabled):hover.box.edit,
a#btns:not(:disabled):hover.box.check,
button#btns:not(:disabled):hover.box.check {
  color: #FFF;
  background-color: #5796d5;
}
a#btns:not(:disabled):hover.box.del,
button#btns:not(:disabled):hover.box.del,
a#btns:not(:disabled):hover.box.cancel,
button#btns:not(:disabled):hover.box.cancel {
  color: #FFF;
  background-color: #f49991;
}
