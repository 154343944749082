/* Font Family */
/* Borda arredondada */
body > #root #modalBox.mprices > .modalBody > .modalContent > .header,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 1px !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos {
  width: 85%;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit {
  text-align: left;
  font-size: 11px;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .price {
  padding: 10px;
  height: 33px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .year:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .month:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .price:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .price:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .price:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .price:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .price:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .price:not(:first-child) {
  margin-left: 2px;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .year,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .year {
  width: 30%;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .month,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .item > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .item > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > .price,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > .price {
  width: 35%;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit {
  display: none;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div {
  display: inline-block;
  padding: 0 !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div:not(:first-child),
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div:not(:first-child) {
  margin-left: 2px;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div > input,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div > input,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div > select,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 11px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div > input,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div > input,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > .edit > div > select,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos > .edit > div > select {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 2px;
  width: 15%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0 10px;
  margin-left: 1px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #666;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:disabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:disabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:disabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #999 !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover {
  color: #FFF;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover:enabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover:enabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover:enabled,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover:enabled {
  cursor: pointer;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover.edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover.edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover.edit {
  background-color: #cc8400;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover.del,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover.del,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.del,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover.del {
  background-color: #cc0000;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover.save,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover.save,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.save,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover.save {
  background-color: #336900;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item > button:hover.cancel,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item > button:hover.cancel,
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit > button:hover.cancel,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit > button:hover.cancel {
  background-color: #8f0048;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .item,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .item {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons > .edit {
  display: none;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .infos > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .infos > .item {
  display: none !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .infos > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .infos > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .infos > .edit,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .infos > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .buttons > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .buttons > .item {
  display: none !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .buttons > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .buttons > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .buttons.active > .buttons > .edit,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .buttons.active > .buttons > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .infos > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .infos > .item {
  display: none !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .infos > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .infos > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .infos > .edit,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .infos > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .buttons > .item,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .buttons > .item {
  display: none !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .buttons > .edit,
body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .buttons > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .header.active > .buttons > .edit,
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line.active > .buttons > .edit {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header {
  margin-bottom: 5px;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .header > .infos > * {
  font-weight: 600;
  background-color: #D0D0D0 !important;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .line > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.add {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #b08403;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.add {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.add:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #977102;
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.save {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 2px;
  padding: 10px 15px;
  width: 15%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #156815;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.save {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox.mprices > .modalBody > .modalContent > .footerBottons > button.save:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #115311;
}
