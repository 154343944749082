/* Font Family */
/* Borda arredondada */
body > #root .log_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .log_line {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line:not(:first-child) {
  margin-top: 1px;
}
body > #root .log_line > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root .log_line > .header {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line > .header > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 15px;
  width: 28%;
  background-color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root .log_line > .header > * {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line > .header > *:not(:first-child) {
  margin-left: 1px;
}
body > #root .log_line > .header > *.hash {
  width: 10%;
}
body > #root .log_line > .header > *.dates {
  width: 18%;
}
body > #root .log_line > .header > *:last-child {
  width: 4%;
}
body > #root .log_line > .header > * > label {
  font-size: 0.7em;
  font-weight: bold;
}
body > #root .log_line > .header > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  cursor: pointer;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root .log_line > .header > button {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line > .header > button.open {
  color: #FFF;
  background-color: #999;
}
body > #root .log_line > .data.none {
  display: none !important;
}
body > #root .log_line > .data > .data_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 1px;
  padding: 10px;
  font-size: 0.9em;
  background-color: #dddddd;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root .log_line > .data > .data_line {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line > .data > .data_line > * {
  padding: 15px;
  width: 33%;
}
body > #root .log_line > .data > .data_line > *:not(:first-child) {
  margin-left: 1px;
}
body > #root .log_line > .data > .data_line > *.status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 1%;
  cursor: help;
}
@media screen and (max-width: 767px) {
  body > #root .log_line > .data > .data_line > *.status {
    flex: 0 0 auto !important;
  }
}
body > #root .log_line > .data > .data_line > *.status.ok {
  background-color: #22A822;
}
body > #root .log_line > .data > .data_line > *.status.err {
  background-color: #EA4335;
}
