/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.mbills > .modalBody > .modalContent {
  position: relative;
  min-height: 300px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 15px;
  width: 50%;
  color: #FFF;
  cursor: default;
  background-color: #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs > div {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs > div:not(:first-child) {
  margin-left: 1px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs > div.active {
  background-color: #323232;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .tabs > div:not(.active):hover {
  cursor: pointer;
  background-color: #666;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow-x: hidden;
  margin-top: 70px;
  margin-bottom: 90px;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > form {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form label > sup {
  color: #EA4335;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form input,
body > #root > #modalBox.mbills > .modalBody > .modalContent > form select {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form input[data-type="date"],
body > #root > #modalBox.mbills > .modalBody > .modalContent > form select[data-type="date"] {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form .MuiFormControl-root {
  visibility: hidden;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form .MuiFormControl-root,
body > #root > #modalBox.mbills > .modalBody > .modalContent > form .MuiFormControl-root * {
  height: 1px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col > h3,
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px;
  padding: 13px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 13px;
  background: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col > h3,
  body > #root > #modalBox.mbills > .modalBody > .modalContent > form > h3 {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col > h3:not(:first-child),
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > h3:not(:first-child) {
  margin-top: 25px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col > h3 > sup,
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > h3 > sup {
  margin-left: 5px;
  color: #EA4335;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box:not(:first-child) {
  margin-top: 30px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col:not(:first-child) {
  margin-left: 15px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > form > div.box > .col > .form-group > .input-group:not(:first-child) {
  margin-left: 15px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .errors {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  margin-bottom: 5px;
  height: 30px;
  margin: 20px;
  margin-bottom: 70px;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .errors > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > .errors > div {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .errors > div > p {
  color: red;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 8px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button.btnSave {
  background-color: #009a00;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button:hover {
  color: #FFF;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button:hover.addBill {
  background-color: #006700;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button.btnLoad {
  color: #FFF;
  background-color: #d88508;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button.btnLoad > svg {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 5000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 5000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > footer > button.btnLoad:hover {
  cursor: not-allowed;
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .loading-simple {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox.mbills > .modalBody > .modalContent > .loading-simple {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox.mbills > .modalBody > .modalContent > .loading-simple > img {
  height: 100px;
}
