/* Font Family */
/* Borda arredondada */
body > #root > header .user {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  width: 40px;
  height: 40px;
  color: #B0B0B0;
  background-color: #e0e0e0;
  overflow: hidden;
  z-index: 999;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
@media screen and (max-width: 767px) {
  body > #root > header .user {
    flex: 0 0 auto !important;
  }
}
body > #root > header .user > img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}
body > #root > header .user > #userBox {
  top: 70px !important;
  left: auto !important;
  right: 20px !important;
  display: block;
  padding: 0;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > header .user > #userBox::after {
  top: -10px;
  left: 88%;
  border-bottom-width: 10px;
}
body > #root > header .user > #userBox > .body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  padding: 15px 20px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  body > #root > header .user > #userBox > .body {
    flex: 0 0 auto !important;
  }
}
body > #root > header .user > #userBox > .body > .name {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
}
body > #root > header .user > #userBox > .body > small {
  margin-top: 3px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #666;
}
body > #root > header .user > #userBox > .footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
@media screen and (max-width: 767px) {
  body > #root > header .user > #userBox > .footer {
    flex: 0 0 auto !important;
  }
}
body > #root > header .user > #userBox > .footer > button,
body > #root > header .user > #userBox > .footer > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 8px;
  color: #FFF;
  cursor: pointer;
  background-color: #F0F0F0;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > header .user > #userBox > .footer > button,
  body > #root > header .user > #userBox > .footer > a {
    flex: 0 0 auto !important;
  }
}
body > #root > header .user > #userBox > .footer > button:not(:first-child),
body > #root > header .user > #userBox > .footer > a:not(:first-child) {
  margin-left: 1px;
}
body > #root > header .user > #userBox > .footer > button > span,
body > #root > header .user > #userBox > .footer > a > span {
  margin-left: 10px;
}
body > #root > header .user > #userBox > .footer > button {
  background-color: #D0D0D0;
}
body > #root > header .user > #userBox > .footer > button.editUser:hover {
  background-color: #1e931e;
}
body > #root > header .user > #userBox > .footer > button.reportMailer:hover {
  background-color: #ff8716;
}
body > #root > header .user > #userBox > .footer > a {
  background-color: #D0D0D0;
}
body > #root > header .user > #userBox > .footer > a:hover {
  background-color: #e82e1e;
}
body > #root > header .user > .editUser {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: absolute;
  width: 50px;
  height: 50px;
  color: #FFF;
  background-color: rgba(51, 51, 51, 0.8);
  border: none;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > header .user > .editUser {
    flex: 0 0 auto !important;
  }
}
body > #root > header .user:hover > .editUser {
  cursor: pointer !important;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root > header .user {
    width: 40px;
    height: 40px;
  }
  body > #root > header .user > img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
  }
  body > #root > header .user > #userBox {
    top: 50px !important;
    right: 12px !important;
    width: 170px;
  }
  body > #root > header .user > #userBox::after {
    top: -10px;
    left: 88%;
  }
  body > #root > header .user > #userBox > .body {
    padding: 10px 12px;
  }
  body > #root > header .user > #userBox > .body > .name {
    font-size: 1.1em;
  }
  body > #root > header .user > #userBox > .body > small {
    font-size: 0.75em;
  }
  body > #root > header .user > #userBox > .footer > button,
  body > #root > header .user > #userBox > .footer > a {
    padding: 8px;
  }
  body > #root > header .user > #userBox > .footer > button > span,
  body > #root > header .user > #userBox > .footer > a > span {
    margin-left: 8px;
  }
  body > #root > header .user > #userBox > .footer > button > svg,
  body > #root > header .user > #userBox > .footer > a > svg {
    width: 11px;
    height: 11px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root > header .user {
    width: 40px;
    height: 40px;
  }
  body > #root > header .user > img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
  }
  body > #root > header .user > #userBox {
    top: 55px !important;
    right: 15px !important;
    width: 180px;
  }
  body > #root > header .user > #userBox::after {
    top: -10px;
    left: 90%;
  }
  body > #root > header .user > #userBox > .body {
    padding: 10px 15px;
  }
  body > #root > header .user > #userBox > .body > .name {
    font-size: 1.2em;
  }
  body > #root > header .user > #userBox > .body > small {
    font-size: 0.8em;
  }
  body > #root > header .user > #userBox > .footer > button,
  body > #root > header .user > #userBox > .footer > a {
    padding: 8px;
  }
  body > #root > header .user > #userBox > .footer > button > span,
  body > #root > header .user > #userBox > .footer > a > span {
    margin-left: 8px;
  }
  body > #root > header .user > #userBox > .footer > button > svg,
  body > #root > header .user > #userBox > .footer > a > svg {
    width: 12px;
    height: 12px;
  }
}
body > #root > header > .user-logout > .user-box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 0.9em;
}
@media screen and (max-width: 767px) {
  body > #root > header > .user-logout > .user-box {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .user-logout > .user-box > .infos {
  text-align: right;
}
body > #root > header > .user-logout > .user-box > .user-image {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  color: #040A10;
  background-color: #f7f7f7;
  overflow: hidden;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
}
@media screen and (max-width: 767px) {
  body > #root > header > .user-logout > .user-box > .user-image {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .user-logout > .user-box > .user-image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  border: none;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}
body > #root > header > .user-logout > .user-box > .user-image > .editUser {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: absolute;
  width: 40px;
  height: 40px;
  color: #FFF;
  background-color: rgba(51, 51, 51, 0.8);
  border: none;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > header > .user-logout > .user-box > .user-image > .editUser {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .user-logout > .user-box > .user-image:hover > .editUser {
  cursor: pointer !important;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
@media screen and (max-width: 767px) {
  body > #root > header > .user-logout > .user-box {
    font-size: 0.8em;
  }
  body > #root > header > .user-logout > .user-box > .user-image {
    display: none !important;
  }
}
