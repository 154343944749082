/* Font Family */
/* Borda arredondada */
body > #root > section.cliBillError > main {
  padding: 30px;
  overflow: hidden;
  display: flex;
  gap: 20px;
}
body > #root > section.cliBillError > main > .header {
  display: flex;
  justify-items: stretch;
  gap: 10px;
  margin-bottom: 20px;
}
body > #root > section.cliBillError > main > .header > .messageInfo {
  margin-bottom: 1rem;
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #fff;
  border-radius: 10px;
  position: absolute;
}
body > #root > section.cliBillError > main > .header > h3 {
  font-size: 0.9rem;
  color: #c3c3c3;
}
body > #root > section.cliBillError > main > .header > h3 > b {
  color: #C0C0C0;
}
body > #root > section.cliBillError > main > .header > .searchBox {
  display: flex;
  gap: 10px;
  position: relative;
}
body > #root > section.cliBillError > main > .header > .searchBox > #faturas {
  padding: 8px 30px 8px 10px;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search {
  padding: 8px 30px 8px 10px;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  transition: all 0.3s ease;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > section.cliBillError > main > .header > .searchBox > button {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  color: #EA4335;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 100%;
  background-color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .client,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .client,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .provider,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .provider {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 30%;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .client,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .client,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .provider,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .provider {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .buttons,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 17%;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .buttons,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .dateInfo,
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .dateInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 23%;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp > .dateInfo,
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp > .dateInfo {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > .headerInfoUp {
  background-color: #FF5144;
  border-bottom: 1px solid #D0D0D0;
  color: #FFF;
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillError > main > .billsErrorsBoxUp > section {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillError > main > .billsErrorsBoxUp > section > .billBoxUp.even-id {
  margin-top: 0px;
  padding: 10px;
  width: 100%;
  background-color: rgba(251, 219, 217, 0.5);
}
