/* Font Family */
/* Borda arredondada */
body > #root #modalBox > .modalBody > .modalContent > .modal_file {
  width: 100%;
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 35px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody > .modalContent > .modal_file .button {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .button > button {
  width: 80%;
  margin-top: 10px;
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .ok {
  border-color: #2d70b3 !important;
  color: #2d70b3 !important;
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .edit {
  background-color: #2d70b3 !important;
  color: #FFF !important;
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .save {
  background-color: #10B981 !important;
  color: #FFF !important;
}
body > #root #modalBox > .modalBody > .modalContent > .modal_file .cancel {
  background-color: #DF0D0D !important;
  color: #FFF !important;
}
body > #root .container > main.bills {
  position: relative;
}
body > #root .container > main.bills > .info-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root .container > main.bills > .info-center {
    flex: 0 0 auto !important;
  }
}
body > #root .container > main.bills > .info-center > p {
  font-size: 1.3em;
  font-weight: bold;
  margin: 10px;
}
body > #root .container > main.bills > .info-center > svg {
  font-size: 45px;
}
body > #root .container > main.bills > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: fixed;
  top: 75px;
  left: 300px;
  right: 20px;
  margin: 0 !important;
  padding: 20px;
  background-color: #f5f5f5;
  z-index: 99;
}
@media screen and (max-width: 767px) {
  body > #root .container > main.bills > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .container > main.bills > .buttons > input[type="file"] {
  display: none;
}
body > #root .container > main.bills > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 8px 15px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .container > main.bills > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .container > main.bills > .buttons > button:not(:nth-child(2)) {
  margin-left: 10px;
}
body > #root .container > main.bills > .buttons > button.addBill {
  background-color: #009a00;
}
body > #root .container > main.bills > .buttons > button.manual {
  background-color: #ef6e66;
}
body > #root .container > main.bills > .buttons > button:hover {
  color: #FFF;
}
body > #root .container > main.bills > .buttons > button:hover.addBill {
  background-color: #006700;
}
body > #root .container > main.bills > .buttons > button:hover.manual {
  background-color: #e82c20;
}
body > #root .container > main.bills > .buttons > button.load {
  color: #FFF;
  background-color: #d88508;
}
body > #root .container > main.bills > .buttons > button.load > svg {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 5000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 5000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root .container > main.bills > .buttons > button.load:hover {
  cursor: not-allowed;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root .container > main.bills > .buttons {
    top: 50px;
    left: 200px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root .container > main.bills > .buttons {
    top: 55px;
    left: 225px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root .container > main.bills > .buttons {
    top: 60px;
    left: 250px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root .container > main.bills > .buttons {
    top: 65px;
    left: 275px;
  }
}
body > #root .container > main.bills > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  body > #root .container > main.bills > .content {
    flex: 0 0 auto !important;
  }
}
body > #root .container > main.bills > .content.alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 0 100px;
  width: 100%;
  height: 100%;
  font-weight: 600;
  color: #b30000;
}
@media screen and (max-width: 767px) {
  body > #root .container > main.bills > .content.alert {
    flex: 0 0 auto !important;
  }
}
body > #root .container > main.bills > .content.loading {
  min-height: calc(100% - 60px);
}
body > #root .container > main.bills > .content.loading > .loading-single {
  padding-bottom: 93px;
}
