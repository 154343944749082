/* Font Family */
/* Borda arredondada */
body > #root .unitsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 32%;
  height: 125px;
  max-height: 125px;
  cursor: default;
  overflow: hidden;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox:not(:nth-child(3n+3)) {
  margin-right: 2%;
}
body > #root .unitsBox:nth-child(n+4) {
  margin-top: 2%;
}
body > #root .unitsBox:hover {
  border-color: #C0C0C0;
}
body > #root .unitsBox > .unitsData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox > .unitsData {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox > .unitsData > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 0;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox > .unitsData > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox > .unitsData > .infos > h4 {
  margin: 0 0 2px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: rgba(255, 81, 68, 0.7);
}
body > #root .unitsBox > .unitsData > .infos > h3 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  width: 92%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: #181818;
}
body > #root .unitsBox > .unitsData > .state {
  position: absolute;
  top: -7px;
  right: 2px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-weight: bolder;
  color: #f2f2f2;
  z-index: 1;
}
body > #root .unitsBox > .unitsData > .testCCEEClient {
  position: absolute;
  bottom: 5px;
  right: 0;
  padding: 5px 10px;
  color: #999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root .unitsBox > .unitsData > .testCCEEClient:hover {
  color: #666;
}
body > #root .unitsBox > .unitsData > .testCCEEClient.waiting {
  color: #e2a904;
}
body > #root .unitsBox > .unitsData > .testCCEEClient.waiting > svg {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root .unitsBox > .unitsData > .testCCEEClient.success {
  color: #197e19;
}
body > #root .unitsBox > .unitsData > .testCCEEClient.error {
  color: #e82e1e;
}
body > #root .unitsBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 0 -1px -1px;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox > .buttons > .subMenu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: -37px;
  left: 0;
  right: 0;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox > .buttons > .subMenu {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox > .buttons > .subMenu > button {
  padding: 10px;
  background-color: #a7a7a7;
}
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip) {
  background-color: #D0D0D0;
  border-color: #D0D0D0 !important;
}
body > #root .unitsBox > .buttons > .subMenu > button,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip),
body > #root .unitsBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  width: 20%;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .unitsBox > .buttons > .subMenu > button,
  body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip),
  body > #root .unitsBox > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .unitsBox > .buttons > .subMenu > button:not(:last-child),
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):not(:last-child),
body > #root .unitsBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover,
body > #root .unitsBox > .buttons > button:hover {
  color: #FFF;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.folderUser,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.folderUser,
body > #root .unitsBox > .buttons > button:hover.folderUser {
  background-color: #666;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.editClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.editClient,
body > #root .unitsBox > .buttons > button:hover.editClient {
  background-color: #e69500;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.deleteClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.deleteClient,
body > #root .unitsBox > .buttons > button:hover.deleteClient {
  background-color: #e60000;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.infosClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.infosClient,
body > #root .unitsBox > .buttons > button:hover.infosClient {
  background-color: #0052b1;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.energyClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.energyClient,
body > #root .unitsBox > .buttons > button:hover.energyClient {
  background-color: #850ba3;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.pricesClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.pricesClient,
body > #root .unitsBox > .buttons > button:hover.pricesClient {
  background-color: #408300;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.demandsClientCont,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.demandsClientCont,
body > #root .unitsBox > .buttons > button:hover.demandsClientCont {
  background-color: #808300;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.demandsClientCons,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.demandsClientCons,
body > #root .unitsBox > .buttons > button:hover.demandsClientCons {
  background-color: #025c32;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.othersClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.othersClient,
body > #root .unitsBox > .buttons > button:hover.othersClient {
  background-color: #103741;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.icmsClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.icmsClient,
body > #root .unitsBox > .buttons > button:hover.icmsClient {
  background-color: #38006d;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.pisCofinsClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.pisCofinsClient,
body > #root .unitsBox > .buttons > button:hover.pisCofinsClient {
  background-color: #a90054;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.cceeClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.cceeClient,
body > #root .unitsBox > .buttons > button:hover.cceeClient {
  background-color: #0a3d8d;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.proinfaClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.proinfaClient,
body > #root .unitsBox > .buttons > button:hover.proinfaClient {
  background-color: #0a8d71;
}
body > #root .unitsBox > .buttons > .subMenu > button:hover.lostEnergyClient,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip):hover.lostEnergyClient,
body > #root .unitsBox > .buttons > button:hover.lostEnergyClient {
  background-color: #7c3a14;
}
body > #root .unitsBox > .buttons > .subMenu > button > span,
body > #root .unitsBox > .buttons > .subMenu > div:not(.__react_component_tooltip) > span,
body > #root .unitsBox > .buttons > button > span {
  margin-left: 10px;
}
body > #root .unitsBox > .buttons > button {
  padding: 10px 15px;
  background-color: #999;
}
body > #root .unitsBox > .buttons > button.active.editClient {
  background-color: #e69500;
}
body > #root .unitsBox > .buttons > button.folderUser {
  max-width: 75px;
}
body > #root .unitsBox > .buttons > button.folderUser.waiting {
  color: #FFF;
  background-color: #e69500 !important;
}
body > #root .unitsBox > .buttons > button.folderUser.success {
  color: #FFF;
  background-color: #006700 !important;
}
body > #root .unitsBox > .buttons > button.folderUser.error {
  color: #FFF;
  background-color: #e60000 !important;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
