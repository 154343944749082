/* Font Family */
/* Borda arredondada */
body > #root > .container > main.documents > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: -20px 0 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  position: relative;
  margin: 0;
  padding: 8px;
  width: 15%;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  z-index: 1;
  border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px !important;
  -moz-border-radius: 0 0 5px 5px !important;
  -webkit-border-radius: 0 0 5px 5px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .buttons > button:not(:first-child) {
  margin-left: 1px;
}
body > #root > .container > main.documents > .buttons > button:hover,
body > #root > .container > main.documents > .buttons > button.active {
  color: #FFF;
}
body > #root > .container > main.documents > .buttons > button:hover.srn,
body > #root > .container > main.documents > .buttons > button.active.srn {
  background-color: #df5a51;
}
body > #root > .container > main.documents > .buttons > button:hover.me,
body > #root > .container > main.documents > .buttons > button.active.me {
  background-color: #cc7400;
}
body > #root > .container > main.documents > .buttons > button.active {
  height: 37px;
  color: #FFF;
  z-index: 2;
}
body > #root > .container > main.documents > .docs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 30px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs {
    flex: 0 0 auto !important;
  }
}
@-moz-document url-prefix() {
  body > #root > .container > main.documents > .docs {
    padding-bottom: 0;
  }
}
body > #root > .container > main.documents > .docs > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  height: 100%;
  font-weight: 600;
  color: #b30000;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs > .alert {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .docs > .docBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 3px;
  width: 100%;
  height: 50px;
  color: #999;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs > .docBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .docs > .docBox:hover,
body > #root > .container > main.documents > .docs > .docBox.active {
  color: #666;
  background-color: #FFF;
}
body > #root > .container > main.documents > .docs > .docBox:hover > .icon,
body > #root > .container > main.documents > .docs > .docBox.active > .icon {
  background-color: #e3e3e3;
}
body > #root > .container > main.documents > .docs > .docBox.folder > .status {
  background-color: #e69500;
}
body > #root > .container > main.documents > .docs > .docBox > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs > .docBox > * {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .docs > .docBox > .status {
  width: 8px;
  height: 100%;
  background-color: #8DAECA;
}
body > #root > .container > main.documents > .docs > .docBox > .status.on {
  background-color: #16a016;
}
body > #root > .container > main.documents > .docs > .docBox > .status.off {
  background-color: #a01616;
}
body > #root > .container > main.documents > .docs > .docBox > .icon {
  margin-left: 3px;
  padding: 10px 15px 10px 13px;
  height: 100%;
  background-color: #F0F0F0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main.documents > .docs > .docBox > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-left: 3px;
  padding: 10px 15px;
  height: 100%;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs > .docBox > .name {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .docs > .docBox > .size {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 2px 3px 0 0;
  padding: 10px 15px;
  width: 120px;
  max-width: 120px;
  height: 94%;
  font-size: 0.8em;
  border-left: 2px solid #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .docs > .docBox > .size {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.documents > .docs > .docBox > a,
body > #root > .container > main.documents > .docs > .docBox button {
  margin-left: 1px;
  padding: 10px 15px;
  height: 100%;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main.documents > .docs > .docBox > a.load,
body > #root > .container > main.documents > .docs > .docBox button.load {
  background-color: #e37d1d;
}
body > #root > .container > main.documents > .docs > .docBox > a.load > svg,
body > #root > .container > main.documents > .docs > .docBox button.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root > .container > main.documents > .docs > .docBox > a.download,
body > #root > .container > main.documents > .docs > .docBox button.download {
  background-color: #69a671;
}
body > #root > .container > main.documents > .docs > .docBox > a.download:hover,
body > #root > .container > main.documents > .docs > .docBox button.download:hover {
  background-color: #518051;
}
body > #root > .container > main.documents > .docs > .docBox > a.open,
body > #root > .container > main.documents > .docs > .docBox button.open,
body > #root > .container > main.documents > .docs > .docBox > a.close,
body > #root > .container > main.documents > .docs > .docBox button.close {
  cursor: pointer;
  background-color: #797979;
}
body > #root > .container > main.documents > .docs > .docBox > a.open:hover,
body > #root > .container > main.documents > .docs > .docBox button.open:hover,
body > #root > .container > main.documents > .docs > .docBox > a.close:hover,
body > #root > .container > main.documents > .docs > .docBox button.close:hover {
  background-color: #555555;
}
body > #root > .container > main.documents > .docs > .docBox > a:hover > svg,
body > #root > .container > main.documents > .docs > .docBox button:hover > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > .container > main.documents > .docs > .docBox > a > svg,
body > #root > .container > main.documents > .docs > .docBox button > svg {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.documents > .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > .container > main.documents > .header > .buttons {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-top: 20px;
  }
  body > #root > .container > main.documents > .header > .buttons > button {
    width: 50%;
  }
  body > #root > .container > main.documents > .header > .buttons > button:first-child {
    margin-left: 0;
  }
  @-moz-document url-prefix() {
    body > #root > .container > main.documents > .docs {
      padding-bottom: 20px;
    }
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main.documents > .header {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > .container > main.documents > .header > .buttons {
    flex: 0 0 auto !important;
  }
}
