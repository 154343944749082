/* Font Family */
/* Borda arredondada */
body > #root > section > main .billBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 15px;
  width: 100%;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox:not(:first-child) {
  margin-top: 20px;
}
body > #root > section > main .billBox > .icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 50px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .icon {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .icon > img {
  height: 80%;
}
body > #root > section > main .billBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0 30px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .infos > .provider,
body > #root > section > main .billBox > .infos > .modality,
body > #root > section > main .billBox > .infos > .date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 120px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos > .provider,
  body > #root > section > main .billBox > .infos > .modality,
  body > #root > section > main .billBox > .infos > .date {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .infos > .provider > small,
body > #root > section > main .billBox > .infos > .modality > small,
body > #root > section > main .billBox > .infos > .date > small {
  font-size: 0.6rem;
  font-weight: 600;
  color: #C0C0C0;
}
body > #root > section > main .billBox > .infos > .provider > span,
body > #root > section > main .billBox > .infos > .modality > span,
body > #root > section > main .billBox > .infos > .date > span {
  font-size: 0.95rem;
  font-weight: 500;
  color: #666;
}
body > #root > section > main .billBox > .infos > .waiting {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #b08403;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos > .waiting {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .infos > .provider {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos > .provider {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .client {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-width: 200px;
  max-width: 200px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .client {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .provider {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-width: 200px;
  max-width: 200px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .provider {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-width: 80px;
  max-width: 80px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .status {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .dateInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-width: 200px;
  max-width: 200px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .dateInfo {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox .status-completed {
  color: #b8cfac;
}
body > #root > section > main .billBox .status-pending {
  color: #dfd880;
}
body > #root > section > main .billBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .buttons > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.50rem 0.75rem;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .billBox > .buttons > * {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .billBox > .buttons > *:disabled {
  color: #C0C0C0 !important;
  border-color: #F0F0F0;
  cursor: default;
}
body > #root > section > main .billBox > .buttons > *.download {
  padding-left: 2rem;
  padding-right: 2rem;
  color: #666;
}
body > #root > section > main .billBox > .buttons > *.reloadEconomy {
  margin-left: 5px;
}
body > #root > section > main .billBox > .buttons > *.reloadEconomy:not(:disabled) {
  color: rgba(30, 147, 30, 0.8);
  border-color: rgba(25, 126, 25, 0.8);
}
body > #root > section > main .billBox > .buttons > *.reloadEconomy.load {
  color: #FFF;
  cursor: default;
  background-color: #1e931e;
}
body > #root > section > main .billBox > .buttons > *.reloadEconomy.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root > section > main .billBox > .buttons > *.del {
  margin-left: 5px;
}
body > #root > section > main .billBox > .buttons > *.del:not(:disabled) {
  color: #ec584c;
  border-color: #ef6e63;
}
body > #root > section > main .billBox > .buttons > *.delete {
  margin-left: 5px;
}
body > #root > section > main .billBox > .buttons > *.delete:not(:disabled) {
  color: #737373;
  border-color: #808080;
}
body > #root > section > main .billBox > .buttons > *.down {
  margin-left: 5px;
}
body > #root > section > main .billBox > .buttons > *.down:not(:disabled) {
  color: #737373;
  border-color: #808080;
}
body > #root > section > main .billBox > .buttons > *.edit {
  margin-left: 5px;
}
body > #root > section > main .billBox > .buttons > *.edit:not(:disabled) {
  color: #ffa149;
  border-color: #ffae62;
}
body > #root > section > main .billBox > .buttons > *:not(:disabled):hover.download {
  color: #333;
  border-color: #999;
}
body > #root > section > main .billBox > .buttons > *:not(:disabled):hover.reloadEconomy {
  color: #197e19;
  border-color: #156815;
}
body > #root > section > main .billBox > .buttons > *:not(:disabled):hover.edit {
  color: #fb7a00;
  border-color: #ff8716;
}
body > #root > section > main .billBox > .buttons > *:not(:disabled):hover.del {
  color: #d62516;
  border-color: #e82e1e;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .billBox > .icon {
    display: none !important;
  }
  body > #root > section > main .billBox > .infos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin: 0;
  }
  body > #root > section > main .billBox > .infos > .date,
  body > #root > section > main .billBox > .infos > .provider {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin-right: 10px;
    width: auto;
  }
  body > #root > section > main .billBox > .infos > .date > small,
  body > #root > section > main .billBox > .infos > .provider > small {
    font-size: 0.55rem;
  }
  body > #root > section > main .billBox > .infos > .date > span,
  body > #root > section > main .billBox > .infos > .provider > span {
    font-size: 0.85rem;
  }
  body > #root > section > main .billBox > .infos > .provider {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section > main .billBox > .buttons {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section > main .billBox > .buttons > * {
    padding: 0.4rem 0.7rem;
  }
  body > #root > section > main .billBox > .buttons > *.download {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos > .date,
  body > #root > section > main .billBox > .infos > .provider {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > section > main .billBox > .infos > .provider {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 991px) and screen and (max-width: 767px) {
  body > #root > section > main .billBox > .buttons {
    flex: 0 0 auto !important;
  }
}
