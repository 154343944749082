/* Font Family */
/* Borda arredondada */
body > #root .infosBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 11px;
  color: #333;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox.loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox.loading {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox > .header {
  margin: 0 !important;
}
body > #root .infosBox > .header > * {
  font-weight: bold;
  background-color: #D0D0D0 !important;
}
body > #root .infosBox > .header > .status {
  background-color: #C0C0C0 !important;
}
body > #root .infosBox > .header > .scroll {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20px;
  background-color: #D0D0D0 !important;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox > .header > .scroll {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox > .statusList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  height: 245px;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox > .statusList {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox > .header,
body > #root .infosBox > .statusList > .status_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 2px 0 0;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox > .header,
  body > #root .infosBox > .statusList > .status_line {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox > .header > .status,
body > #root .infosBox > .statusList > .status_line > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 4%;
  cursor: help;
  background-color: #22A822;
}
@media screen and (max-width: 767px) {
  body > #root .infosBox > .header > .status,
  body > #root .infosBox > .statusList > .status_line > .status {
    flex: 0 0 auto !important;
  }
}
body > #root .infosBox > .header > .status.err,
body > #root .infosBox > .statusList > .status_line > .status.err {
  background-color: #EA4335;
}
body > #root .infosBox > .header > .unit,
body > #root .infosBox > .statusList > .status_line > .unit,
body > #root .infosBox > .header > .mail,
body > #root .infosBox > .statusList > .status_line > .mail {
  margin-left: 2px;
  padding: 10px;
  display: inline-block;
  width: 48%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  background-color: #F0F0F0;
}
