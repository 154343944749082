/* Font Family */
/* Borda arredondada */
body > #root > .container > main.report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .header > .title {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 40%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .header > .title {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .header > .title h1 {
  margin: 0px;
  font-size: 24px;
}
body > #root > .container > main.report > .header > .title > p {
  margin: 0px;
  font-size: 10px;
}
body > #root > .container > main.report > .header > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .header > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .header > .buttons > .btnExcel,
body > #root > .container > main.report > .header > .buttons > .btnPDF,
body > #root > .container > main.report > .header > .buttons > .btnLoad {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-right: 10px;
  padding: 5px 10px;
  width: 110px;
  height: 30px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #888888;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .header > .buttons > .btnExcel,
  body > #root > .container > main.report > .header > .buttons > .btnPDF,
  body > #root > .container > main.report > .header > .buttons > .btnLoad {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .header > .buttons > .btnExcel > span,
body > #root > .container > main.report > .header > .buttons > .btnPDF > span,
body > #root > .container > main.report > .header > .buttons > .btnLoad > span {
  margin-left: 5px;
  font-size: 0.9em;
}
body > #root > .container > main.report > .header > .buttons > .btnExcel:hover {
  color: #FFF;
  background-color: green;
}
body > #root > .container > main.report > .header > .buttons > .btnPDF:hover {
  color: #FFF;
  background-color: #8a0000;
}
body > #root > .container > main.report > .header > .buttons > .btnLoad {
  color: #FFF;
  cursor: default;
  background-color: #e37d1d;
}
body > #root > .container > main.report > .header > .buttons > .btnLoad > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root > .container > main.report > .header > .buttons > .months {
  font-size: 10px;
}
body > #root > .container > main.report > .header > .buttons > .months > p {
  margin: 0 0 3px 2px;
  font-size: 1em;
  color: #666;
}
body > #root > .container > main.report > .header > .buttons > .months > select {
  margin: 0;
  padding: 5px 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1em;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .container > main.report > #print {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > #print {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > #print > .headerPrint {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > #print > .headerPrint {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > #print > .headerPrint > h2 {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 2.2em;
}
body > #root > .container > main.report > #print > .headerPrint > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > #print > .headerPrint > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .headerLine,
body > #root > .container > main.report > #print > .headerLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 14px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .headerLine,
  body > #root > .container > main.report > #print > .headerLine {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .headerLine > div > b,
body > #root > .container > main.report > #print > .headerLine > div > b {
  margin-right: 10px;
}
body > #root > .container > main.report > .savings,
body > #root > .container > main.report > #print > .savings {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .savings,
  body > #root > .container > main.report > #print > .savings {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .savings > .economy,
body > #root > .container > main.report > #print > .savings > .economy {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: 49%;
  color: #000;
  background-color: #D6ECC6;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .savings > .economy,
  body > #root > .container > main.report > #print > .savings > .economy {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .savings > .economy.neg,
body > #root > .container > main.report > #print > .savings > .economy.neg {
  background-color: #F8E0D0;
}
body > #root > .container > main.report > .savings > .economy > .label,
body > #root > .container > main.report > #print > .savings > .economy > .label {
  font-size: 0.9em;
  font-weight: 900;
  color: #666;
}
body > #root > .container > main.report > .savings > .economy > .value,
body > #root > .container > main.report > #print > .savings > .economy > .value {
  font-size: 3.5em;
  font-weight: 300;
}
body > #root > .container > main.report > .report,
body > #root > .container > main.report > #print > .report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 25px;
  padding: 20px 25px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .report,
  body > #root > .container > main.report > #print > .report {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .report > h3,
body > #root > .container > main.report > #print > .report > h3,
body > #root > .container > main.report > .report > div,
body > #root > .container > main.report > #print > .report > div {
  font-size: 1em;
}
body > #root > .container > main.report > .reportDefinitive,
body > #root > .container > main.report > #print > .reportDefinitive {
  background-color: #ddf8e4;
}
body > #root > .container > main.report > .legends,
body > #root > .container > main.report > #print > .legends {
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
}
body > #root > .container > main.report > .legends > li,
body > #root > .container > main.report > #print > .legends > li {
  font-size: 12px;
  font-style: italic;
  line-height: 24px;
  color: #333;
}
body > #root > .container > main.report > .alert-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .alert-center {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .alert-center > small {
  margin-top: -5px;
}
body > #root > .container > main.report > .alert-center > small > a {
  cursor: pointer;
  text-decoration: underline;
}
body > #root > .container > main.report > .alert-center > .monthsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .alert-center > .monthsBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .alert-center > .monthsBox > b {
  font-size: 0.85em;
  color: #666;
}
body > #root > .container > main.report > .alert-center > .monthsBox > select {
  margin-top: 8px;
  padding: 6px 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 0.75em;
  color: #333;
  border: 1.5px solid #D0D0D0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > .container > main.report > .alert-center > .alert {
  font-weight: 600;
  color: #b30000;
}
body > #root > .container > main.report > .info-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .info-center {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.report > .info-center > p {
  font-size: 1.3em;
  font-weight: bold;
  margin: 10px;
}
body > #root > .container > main.report > .info-center > svg {
  font-size: 45px;
}
body > #root > .container > main.report > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
  padding: 30px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
  text-align: center;
  background-color: #183d62;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.report > .infos {
    flex: 0 0 auto !important;
  }
}
