/* Font Family */
/* Borda arredondada */
body > #root > .container > main .economyBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important;
  margin-bottom: 40px;
  padding: 15px;
  width: 100%;
  background-color: #FFF;
  border: solid 1px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main .economyBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main .economyBox > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main .economyBox > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main .economyBox > .header > div {
  margin: 0;
  display: inline-block;
  font-size: 1em;
}
body > #root > .container > main .economyBox > .header > div.eco {
  margin: 5px 0 4px;
  font-weight: bold;
  font-size: 1.6em;
}
body > #root > .container > main .economyBox > .budget {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main .economyBox > .budget {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main .economyBox > .budget > img {
  width: 35%;
}
body > #root > .container > main .economyBox > .warning {
  width: 73%;
  font-size: 0.9em;
  text-align: center;
}
