/* Font Family */
/* Borda arredondada */
body > #root .unitBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  background-color: rgba(24, 24, 24, 0.05);
  border: none;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
@media screen and (max-width: 767px) {
  body > #root .unitBox {
    flex: 0 0 auto !important;
  }
}
body > #root .unitBox:hover {
  background-color: rgba(24, 24, 24, 0.12);
}
body > #root .unitBox.active {
  cursor: default;
  background-color: rgba(255, 81, 68, 0.12);
}
body > #root .unitBox.active > h4 {
  color: #aa0c00;
}
body > #root .unitBox.active > h5 {
  color: #4b4b4b;
}
body > #root .unitBox > h4 {
  margin: 0 0 3px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  color: rgba(24, 24, 24, 0.65);
}
body > #root .unitBox > h5 {
  margin: 0;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  color: #999;
}
