/* Font Family */
/* Borda arredondada */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #f2f2f2;
  overflow: hidden;
}
body * {
  box-sizing: border-box;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: 0;
}
body a {
  text-decoration: none;
  color: #040A10;
}
body hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 35px 0;
  width: 100%;
  height: 3px;
  min-height: 3px;
  background-color: #F0F0F0;
  border: none;
}
@media screen and (max-width: 767px) {
  body hr {
    flex: 0 0 auto !important;
  }
}
body > #root {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  height: 100%;
}
@media screen and (max-width: 767px) {
  body > #root {
    flex: 0 0 auto !important;
  }
}
body > #root > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: calc(100vh - 60px - 35px);
}
@media screen and (max-width: 767px) {
  body > #root > .container {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 480px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px);
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
body > #root > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > section {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  height: calc(100vh - 75px);
  color: #666;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
@media screen and (max-width: 767px) {
  body > #root > section > main {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main > .header > h1,
body > #root > section > main > .header > h1 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #666;
}
body > #root > section > main > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0;
  display: flex;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > section > main > .container {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main > .container.alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 150px;
  color: #EA4335;
}
@media screen and (max-width: 767px) {
  body > #root > section > main > .container.alert {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .__react_component_tooltip {
  font-size: 11px;
}
body > #root > section > main .__react_component_tooltip::before {
  height: auto;
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root > section > main {
    height: calc(100vh - 60px);
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root > section > main {
    height: calc(100vh - 65px);
  }
}
.dark > body > #root > section > main {
  background-color: #333;
}
.dark > body > #root > section > main > h1 {
  color: #999;
}
