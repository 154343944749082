/* Font Family */
/* Borda arredondada */
body > #root > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  height: 75px;
  background: #FFF;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  body > #root > header {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .logo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
@media screen and (max-width: 767px) {
  body > #root > header > .logo {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .logo > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > header > .logo > a {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .logo > a img {
  height: 70%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > header > .section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
}
@media screen and (max-width: 767px) {
  body > #root > header > .section {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .section > h1 {
  margin: 0;
  padding-left: 20px;
  font-size: 1.6em;
  font-weight: 700;
  color: #222222;
}
body > #root > header > .section > h1 > small {
  font-size: 0.75em;
  font-weight: 300;
  color: #999;
}
body > #root > header > .section > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 15px 20px;
}
@media screen and (max-width: 767px) {
  body > #root > header > .section > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .section > .infos > * {
  margin-left: 20px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > header > .section > .infos .provider_unavailable {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.6em;
  color: #ff942f;
}
body > #root > header > .section > .infos .unit-name {
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 20px;
  font-size: 1.1em;
  overflow: hidden;
  max-width: 250px;
  color: #666;
}
body > #root > header > .section > .infos > button {
  cursor: pointer;
  border: none;
}
body > #root > header > .section > .infos > .content-info {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background-color: #f2f2f2;
  color: #666;
  min-width: 155px;
  height: 40px;
  font-size: 12px;
  text-align: center;
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
}
@media screen and (max-width: 767px) {
  body > #root > header > .section > .infos > .content-info {
    flex: 0 0 auto !important;
  }
}
body > #root > header > .section > .infos > .content-info > .economy > p {
  font-weight: bold;
  margin: 0px;
}
body > #root > header > .section > .infos > .content-info > .economy > p > sup {
  margin: 0 0 0 1px;
  font-size: 8px;
  font-weight: normal;
  vertical-align: text-top;
}
body > #root > header > .section > .infos > .content-info > img {
  margin-left: 15px;
  width: 22px;
}
body > #root > header > .section > .infos > .__react_component_tooltip {
  margin-left: -10px;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > header > .section > .buttonsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > header > .section > .buttonsBox {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) {
  body > #root > header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 3 !important;
    -ms-flex-positive: 3 !important;
    flex-grow: 3 !important;
    width: 100vw;
    max-height: 50px;
  }
  body > #root > header > .logo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    width: auto;
    max-width: none;
    min-width: auto;
  }
  body > #root > header > .logo:before {
    display: none !important;
  }
  body > #root > header > .logo > a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > header > .logo > a > img {
    height: 80%;
  }
  body > #root > header > .back,
  body > #root > header > .logout,
  body > #root > header > .blank {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    width: 50px;
    height: 100%;
    font-size: 1.2em;
    border: none;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > header {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > header > .logo {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > header > .logo > a {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) and screen and (max-width: 767px) {
  body > #root > header > .back,
  body > #root > header > .logout,
  body > #root > header > .blank {
    flex: 0 0 auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > header {
    height: 50px;
    min-height: 50px;
  }
  body > #root > header > .logo {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
  body > #root > header > .logo > a > img {
    height: 75%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > header {
    height: 55px;
    min-height: 55px;
  }
  body > #root > header > .logo {
    width: 225px;
    min-width: 225px;
    max-width: 225px;
  }
  body > #root > header > .logo > a > img {
    height: 75%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root > header {
    height: 60px;
    min-height: 60px;
  }
  body > #root > header > .logo {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
  }
  body > #root > header > .logo > a > img {
    height: 75%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root > header {
    height: 65px;
    min-height: 65px;
  }
  body > #root > header > .logo {
    width: 275px;
    min-width: 275px;
    max-width: 275px;
  }
  body > #root > header > .logo > a > img {
    height: 75%;
  }
}
body > #root > #modalBox .modalBody > .modalContent > .unitBox:not(:first-child) {
  margin-top: 5px;
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox {
  margin-left: auto;
  width: 100%;
  margin: 5px 0px;
  padding: 8px;
  border: 1px solid #D0D0D0;
  color: #666;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > #modalBox > .modalBody > .modalContent > .searchBox {
    flex: 0 0 auto !important;
  }
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox > #search {
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  margin: 0px;
  border: none;
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
  outline-width: 0;
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > #modalBox > .modalBody > .modalContent > .searchBox > svg {
  margin-left: 5px;
}
@-ms-keyframes bell {
  0% {
    -ms-transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(15deg);
  }
  50% {
    -ms-transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-15deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}
@-moz-keyframes bell {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(15deg);
  }
  50% {
    -moz-transform: rotate(0deg);
  }
  75% {
    -moz-transform: rotate(-15deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes bell {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes bell {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
