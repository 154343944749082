/* Font Family */
/* Borda arredondada */
body > #root > .container > main > .pagination {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > .pagination {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > .pagination > span {
  margin: 0 20px;
}
body > #root > .container > main > .pagination > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  background-color: #FF5144;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main > .pagination > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main > .pagination > button:hover {
  color: #FFF;
  background-color: #DB463B;
}
