/* Font Family */
/* Borda arredondada */
body > #root > .container > main.dash > .lineBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 20px;
  width: 73%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 10px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > .title {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 40%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors > .title {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > .title h1 {
  margin: 0px;
  font-size: 24px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > .title > p {
  margin: 0px;
  font-size: 10px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > div > p {
  margin: 5px 0px;
  font-size: 8px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-right: 15px;
  border-right: 1px solid #D0D0D0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 15px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div > div > p {
  margin: 0px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .export > div > div > svg {
  margin-right: 7px;
  font-size: 18px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox {
  margin-left: 20px;
  font-size: 10px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > p,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > p {
  margin: 0 0 3px 2px;
  font-size: 1em;
  color: #666;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > div,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > div,
  body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > div > *,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > div > * {
  padding: 4px 10px 5px;
  color: #4d4d4d;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > div > *:not(:first-child),
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > div > *:not(:first-child) {
  margin-left: 5px;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > div > *.active,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > div > *.active {
  color: #FFF;
  background-color: #181818;
  border-color: #4b4b4b;
}
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthSelectBox > select,
body > #root > .container > main.dash > .lineBox > .left > .selectors > div > .monthsYearsBox > select {
  margin: 0;
  padding: 6px 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1em;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .container > main.dash > .lineBox > .left > .cards {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .cards {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .cards > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 5px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .cards > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .cards > div:first-child {
  margin-left: 0;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > div:last-child {
  margin-right: 0;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box {
  width: 30%;
  border: solid 1px #d0d0d0;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box > div > svg {
  margin-top: -10px;
  text-align: end;
  font-size: 14px;
  color: #c0c0c0;
  float: right;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box > p {
  margin: 15px 0px;
  font-weight: bold;
  font-size: 28px;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-box > p > sup {
  margin-left: 1px;
  font-size: 16px;
  vertical-align: revert;
}
body > #root > .container > main.dash > .lineBox > .left > .cards .period {
  font-size: 14px;
  color: #FFF;
  background-color: #7e7e7e;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-graphic {
  width: 20%;
  border: solid 1px #d0d0d0;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-graphic > div {
  width: 100%;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-graphic > div > svg {
  margin-top: -10px;
  font-size: 14px;
  color: #c0c0c0;
  text-align: end;
  float: right;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-graphic > span {
  margin: 5px 0px;
  font-size: 16px;
  font-weight: bold;
}
body > #root > .container > main.dash > .lineBox > .left > .cards > .card-graphic > p {
  margin: 0px;
  font-size: 9px;
}
body > #root > .container > main.dash > .lineBox > .left > .chart {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 20px;
  padding: 20px 30px;
  background-color: #FFF;
  border: solid 1px #d0d0d0;
  border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .chart {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .chart > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .left > .chart > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .left > .chart > .header > div > .title {
  margin: 0px;
  font-size: 14px;
  font-weight: bold;
}
body > #root > .container > main.dash > .lineBox > .left > .chart > .header > div > .subtitle {
  margin: 10px 0px;
  font-size: 12px;
}
body > #root > .container > main.dash > .lineBox > .left > .chart > .header > div > select {
  margin-right: 25px;
  padding: 5px 10px;
  color: #4d4d4d;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .container > main.dash > .lineBox > .left > .chart > .header > div > svg {
  color: #c0c0c0;
}
body > #root > .container > main.dash > .lineBox > .right {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .right {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .lineBox > .right > .economyBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 65px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .lineBox > .right > .economyBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  height: 100%;
  font-weight: 600;
  color: #b30000;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .alert {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .info-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.dash > .info-center {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.dash > .info-center > p {
  font-size: 1.3em;
  font-weight: bold;
  margin: 10px;
}
body > #root > .container > main.dash > .info-center > svg {
  font-size: 45px;
}
