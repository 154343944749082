/* Font Family */
/* Borda arredondada */
body > #root > .container > main.adm_units > .unitsContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: fixed;
  top: 75px;
  left: 300px;
  right: 0;
  margin: 0 !important;
  padding: 20px;
  background-color: #f5f5f5;
  z-index: 99;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin-right: 5px !important;
  padding: 8px;
  width: 35px;
  height: 35px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.addClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.addClient {
  background-color: #009a00;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient {
  padding: 0;
  background-color: #a90054;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > span,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > span,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > span,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 9px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > span,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > span,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > span,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > span {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit {
  position: absolute;
  top: 45px;
  width: 120px;
  display: none;
  color: #666;
  background-color: #FFF;
  z-index: 999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit:before {
  content: '';
  position: absolute;
  top: -10px;
  margin-left: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #FFF transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit.error,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit.error,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit.error,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit.error {
  background-color: #fdefee;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit.error:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit.error:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit.error:before,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit.error:before {
  border-color: transparent transparent #fdefee transparent;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit.active,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit.active,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit.active,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit.active {
  display: block;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #666;
  text-align: center;
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > input {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 8px;
  width: 49%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #666;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns > button:hover {
  color: #FFF;
  cursor: pointer;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns > button:hover.save {
  background-color: #336900;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.pisCofinsClient > .edit > .btns > button:hover.cancel,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.pisCofinsClient > .edit > .btns > button:hover.cancel,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient > .edit > .btns > button:hover.cancel,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient > .edit > .btns > button:hover.cancel {
  background-color: #b40018;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.lostEnergyClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.lostEnergyClient {
  background-color: #7c3a14;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button.reportMailer,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div.reportMailer {
  background-color: #fb7a00;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div:hover {
  color: #FFF;
  background-color: #737373;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button:hover.addClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div:hover.addClient {
  background-color: #006700;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button:hover.pisCofinsClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div:hover.pisCofinsClient {
  background-color: #8f0048;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button:hover.lostEnergyClient,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div:hover.lostEnergyClient {
  background-color: #662f10;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button:hover.reportMailer,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div:hover.reportMailer {
  background-color: #c86100;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  width: 120px;
  height: 40px;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > input,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #666;
  text-align: center;
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > input,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > input {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > input:disabled,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > input:disabled,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > input:disabled,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > input:disabled {
  color: #C0C0C0;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 12px;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #666;
  border: none;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button,
  body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button:last-child,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button:last-child,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button:last-child,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button:last-child {
  margin-left: 1px;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button.loading,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button.loading,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button.loading,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button.loading {
  background-color: #b48400;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button.loading > svg,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button.loading > svg,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button.loading > svg,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button.loading > svg {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 5000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 5000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button:hover,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button:hover {
  color: #FFF;
  cursor: pointer;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #pisCofinsBox > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #pisCofinsBox > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > button > #lostEnergyBox > .btns > button:hover.save,
body > #root > .container > main.adm_units > .unitsContent > .header > .buttons > div > #lostEnergyBox > .btns > button:hover.save {
  background-color: #336900;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: auto;
  width: 35%;
  overflow: hidden;
  color: #666;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 5px 10px;
  height: 100%;
  font-size: 0.9rem;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > #search {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > .btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  width: 40px;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > .btn {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > .btn.lnk {
  cursor: pointer;
}
body > #root > .container > main.adm_units > .unitsContent > .header > .searchBox > .btn.lnk:hover {
  color: #FFF;
  background-color: #bf2114;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container > main.adm_units > .unitsContent > .header {
    top: 50px;
    left: 200px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container > main.adm_units > .unitsContent > .header {
    top: 55px;
    left: 225px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root > .container > main.adm_units > .unitsContent > .header {
    top: 60px;
    left: 250px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root > .container > main.adm_units > .unitsContent > .header {
    top: 65px;
    left: 275px;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .units {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-top: 60px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > main.adm_units > .unitsContent > .units {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > main.adm_units > .unitsContent > .units.loading {
  min-height: calc(100% - 60px);
}
body > #root > .container > main.adm_units > .unitsContent > .units.loading > .loading-single {
  padding-bottom: 93px;
}
