/* Font Family */
/* Borda arredondada */
body > #root #modalBox > .modalBody.musers > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.musers > .modalContent > form {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.musers > .modalContent > form .no-margin {
  margin: 0 !important;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .imageBox > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .imageBox > input#image {
  display: none;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > hr {
  margin: 30px 0;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group:not(:first-child),
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group:not(:first-child) {
  margin-top: 20px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group,
  body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > label,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > label {
  font-size: 0.8em;
  font-weight: 600;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input.error,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input.error,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select.error,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select.error {
  background-color: #fdefee;
  border: 1px solid #d62516;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input.error:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input.error:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select.error:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select.error:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input.error:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input.error:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select.error:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select.error:focus {
  background-color: #fef8f7;
  border-color: #a81d11;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select:hover,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > input:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > input:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group > select:focus,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group > select:focus {
  border-color: #333;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w50,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w50 {
  width: 50px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w100,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w100 {
  width: 100px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w140,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w140 {
  width: 140px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w150,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w150 {
  width: 150px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w200,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w200 {
  width: 200px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w220,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w220 {
  width: 220px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w250,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w250 {
  width: 250px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w300,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w300 {
  width: 300px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w350,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w350 {
  width: 350px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w400,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w400 {
  width: 400px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w450,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w450 {
  width: 450px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w500,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w500 {
  width: 500px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.w550,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.w550 {
  width: 550px;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group.mg-none,
body > #root #modalBox > .modalBody.musers > .modalContent > form > .input-group.mg-none {
  margin: 0;
}
body > #root #modalBox > .modalBody.musers > .modalContent > form > .form-group > .input-group:not(:first-child) {
  margin-left: 15px;
}
