/* Font Family */
/* Borda arredondada */
body > #root aside {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  align-content: space-around !important;
  padding: 20px 0;
  min-width: 300px;
  max-width: 300px;
  background: #FFF;
  border-right: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  body > #root aside {
    flex: 0 0 auto !important;
  }
}
body > #root aside > nav {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  body > #root aside > nav {
    flex: 0 0 auto !important;
  }
}
body > #root aside > nav > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin-top: 10px;
  padding: 10px 15px;
  max-width: 80%;
  color: #999;
}
@media screen and (max-width: 767px) {
  body > #root aside > nav > a {
    flex: 0 0 auto !important;
  }
}
body > #root aside > nav > a > * {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root aside > nav > a > span {
  margin-left: 1rem;
  font-size: 0.85rem;
  color: #666;
}
body > #root aside > nav > a.active {
  color: rgba(255, 255, 255, 0.8);
}
body > #root aside > nav > a.active > * {
  z-index: 3;
}
body > #root aside > nav > a.active > span {
  color: #FFF;
}
body > #root aside > nav > a.active:before,
body > #root aside > nav > a.active:after {
  content: '';
  position: absolute;
  background-color: #ff483a;
}
body > #root aside > nav > a.active:before {
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
body > #root aside > nav > a.active:after {
  content: '';
  position: absolute;
  right: -30px;
  width: 40px;
  height: 100%;
  border-radius: 0 50% 50% 0;
  z-index: 1;
}
body > #root aside > nav > a:hover:not(.active) {
  color: #666;
}
body > #root aside > nav > a:hover:not(.active) > span {
  color: #333;
}
body > #root aside > nav > .submenu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  body > #root aside > nav > .submenu {
    flex: 0 0 auto !important;
  }
}
body > #root aside > nav > .submenu:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 15px;
  left: 23px;
  width: 2px;
  background-color: #C0C0C0;
}
body > #root aside > nav > .submenu > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin-top: 10px;
  padding: 10px 15px;
  max-width: 80%;
  color: #999;
}
@media screen and (max-width: 767px) {
  body > #root aside > nav > .submenu > a {
    flex: 0 0 auto !important;
  }
}
body > #root aside > nav > .submenu > a:after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: -5px;
  width: 15px;
  height: 2px;
  background-color: #C0C0C0;
}
body > #root aside > nav > .submenu > a.active:after {
  width: 10px;
  z-index: 0;
}
body > #root aside > nav > .submenu > a.active:before {
  content: "";
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: 5px;
  right: 30px;
  background-color: #F0F0F0;
  z-index: 1;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
body > #root aside > nav > .submenu > a.active > span {
  position: relative;
  color: #666;
  z-index: 2;
}
body > #root aside > nav > .submenu > a.active > svg {
  position: relative;
  color: #666;
  z-index: 999;
}
body > #root aside > nav > .submenu > a > span {
  margin-left: 0.7rem;
  font-size: 0.85rem;
  color: #666;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root aside > nav > .submenu > a:hover > span {
  color: #666;
}
body > #root aside > footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px 20px 0 20px;
}
@media screen and (max-width: 767px) {
  body > #root aside > footer {
    flex: 0 0 auto !important;
  }
}
body > #root aside > footer > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root aside > footer > div {
    flex: 0 0 auto !important;
  }
}
body > #root aside > footer > div > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #666;
  background-color: #e6e6e6;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root aside > footer > div > a {
    flex: 0 0 auto !important;
  }
}
body > #root aside > footer > div > a:not(:first-child) {
  margin-left: 10px;
}
body > #root aside > footer > div > a:hover {
  color: #333;
}
body > #root aside > footer > div > a:hover.logout {
  color: #EA4335;
}
body > #root aside > footer > div > a:hover.config > svg {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 6000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 6000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 6000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root aside > footer > span {
  margin-bottom: 2px;
  font-size: 0.8rem;
  color: #999;
}
body > #root > #menu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: #FFF;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  body > #root > #menu {
    flex: 0 0 auto !important;
  }
}
body > #root > #menu > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  width: 20%;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root > #menu > a {
    flex: 0 0 auto !important;
  }
}
body > #root > #menu > a.active {
  color: #333;
}
body > #root > #menu > a.active > span {
  margin: 7px 0 4px;
  width: 50%;
  height: 6px;
  color: #ff392a;
  overflow: hidden;
  background-color: #ff392a;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #menu > a > span {
  margin-top: 3px;
  font-size: 0.7rem;
}
body > #root > #menu > a > svg {
  font-size: 1.8em;
}
body > #root > #menu > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
@media screen and (max-width: 767px) {
  body > #root > #menu > div {
    flex: 0 0 auto !important;
  }
}
body > #root > #menu > div > a {
  padding: 9px 11px 7px;
  color: #333;
  background-color: #F0F0F0;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
}
body > #root > #menu > div > a.active {
  color: #000;
  background-color: #ff392a;
}
body > #root > #menu > div > a > svg {
  font-size: 2em;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root aside {
    min-width: 200px;
    max-width: 200px;
    height: calc(100vh - 50px);
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root aside {
    min-width: 225px;
    max-width: 225px;
    height: calc(100vh - 55px);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body > #root aside {
    min-width: 250px;
    max-width: 250px;
    height: calc(100vh - 60px);
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  body > #root aside {
    min-width: 275px;
    max-width: 275px;
    height: calc(100vh - 65px);
  }
}
.dark > body > #root aside {
  border-right: 1px solid #4d4d4d;
}
.dark > body > #root aside > nav > a {
  color: #666;
}
.dark > body > #root aside > nav > a > span {
  color: #999;
}
.dark > body > #root aside > nav > a.active {
  color: #333;
}
.dark > body > #root aside > nav > a.active > span {
  color: #000;
}
.dark > body > #root aside > nav > a:hover:not(.active) {
  color: #C0C0C0;
}
.dark > body > #root aside > nav > a:hover:not(.active) > span {
  color: #F0F0F0;
}
.dark > body > #root aside > footer > a {
  color: #999;
  background-color: #4d4d4d;
}
.dark > body > #root aside > footer > a:hover {
  color: #F0F0F0;
}
.dark > body > #root aside > footer > span {
  color: #666;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
