/* Font Family */
/* Borda arredondada */
body > #root #modalBox > .modalBody.mprofile > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.mprofile > .modalContent > form {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .imageBox > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .imageBox > input#image {
  display: none;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > hr {
  margin: 30px 0 10px;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group {
  margin-top: 20px;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group,
  body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > label,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > label {
  font-size: 0.8em;
  font-weight: 600;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input.error,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input.error {
  background-color: #fdefee;
  border: 1px solid #d62516;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input.error:hover,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input.error:hover,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input.error:focus,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input.error:focus {
  background-color: #fef8f7;
  border-color: #a81d11;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input:hover,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input:hover,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input:focus,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input:focus {
  border-color: #333;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group > input:disabled,
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .input-group > input:disabled {
  border: 1px solid #999 !important;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > .form-group > .input-group:not(:first-child) {
  margin-left: 15px;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > form > small {
  margin-top: 15px;
  font-size: 0.85em;
  font-style: italic;
  color: #EA4335;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > .loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: -30px;
  height: 309px;
}
@media screen and (max-width: 767px) {
  body > #root #modalBox > .modalBody.mprofile > .modalContent > .loading {
    flex: 0 0 auto !important;
  }
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > .loading.sent > .icon {
  height: 220px !important;
}
body > #root #modalBox > .modalBody.mprofile > .modalContent > .loading > .icon {
  height: 100px;
}
