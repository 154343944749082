/* Font Family */
/* Borda arredondada */
body > #root > section > main .economyChart {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > section > main .economyChart > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > header {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > header > .titles > h3 {
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #6B7280;
}
body > #root > section > main .economyChart > header > .titles > p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #607D8B;
}
body > #root > section > main .economyChart > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > svg {
  min-height: 250px !important;
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper {
  outline: none;
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart {
  padding: 15px;
  background-color: #FFF;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > h4 {
  margin: 0 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 5px 0 0;
  font-size: 0.95rem;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > div {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  bottom: -10px !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div,
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  padding: 15px 0 0 !important;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div,
  body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div:not(:first-child),
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div:not(:first-child) {
  margin-left: 20px;
}
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div > .color,
body > #root > section > main .economyChart > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div > .color {
  margin-right: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section > main .economyChart > footer > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > footer > .line {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main .economyChart > footer > .line:not(:first-child) {
  margin-top: 10px;
}
body > #root > section > main .economyChart > footer > .line > span {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 12px;
  color: #4d4d4d;
}
body > #root > section > main .economyChart > footer > .line > span.ball {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  background-color: #003063;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main .economyChart > footer > .line > span.ball {
    flex: 0 0 auto !important;
  }
}
