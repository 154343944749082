/* Font Family */
/* Borda arredondada */
body > #root .mail_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line:not(:first-child) {
  margin-top: 1px;
}
body > #root .mail_line > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 15px;
  width: 28%;
  background-color: #FFF;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header > * {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > *:not(:first-child) {
  margin-left: 1px;
}
body > #root .mail_line > .header > *.name > div,
body > #root .mail_line > .header > *.mail > div,
body > #root .mail_line > .header > *.unit > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header > *.name > div,
  body > #root .mail_line > .header > *.mail > div,
  body > #root .mail_line > .header > *.unit > div {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > *.name > div > span,
body > #root .mail_line > .header > *.mail > div > span,
body > #root .mail_line > .header > *.unit > div > span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root .mail_line > .header > *.name,
body > #root .mail_line > .header > *.mail {
  width: 15%;
}
body > #root .mail_line > .header > *.unit {
  width: 20%;
}
body > #root .mail_line > .header > *.report,
body > #root .mail_line > .header > *.sales_off,
body > #root .mail_line > .header > *.backup_power,
body > #root .mail_line > .header > *.ccee_fees,
body > #root .mail_line > .header > *.ccee_ercap {
  width: 8%;
}
body > #root .mail_line > .header > *.buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 10%;
  padding: 0;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header > *.buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > *.buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header > *.buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > *.buttons > button:hover {
  color: #FFF;
}
body > #root .mail_line > .header > *.buttons > button:hover.editMail {
  background-color: #fb7a00;
}
body > #root .mail_line > .header > *.buttons > button:hover.delMail {
  margin-left: 2px;
  background-color: #d62516;
}
body > #root .mail_line > .header > *.buttons > button.editMail {
  background-color: #ff942f;
}
body > #root .mail_line > .header > *.buttons > button.delMail {
  margin-left: 1px;
  background-color: #EA4335;
}
body > #root .mail_line > .header > * > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  font-size: 0.7em;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  body > #root .mail_line > .header > * > label {
    flex: 0 0 auto !important;
  }
}
body > #root .mail_line > .header > * > label > span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
