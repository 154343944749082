/* Font Family */
/* Borda arredondada */
body > #root .userBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 32%;
  cursor: default;
  overflow: hidden;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .userBox {
    flex: 0 0 auto !important;
  }
}
body > #root .userBox:not(:nth-child(3n+3)) {
  margin-right: 2%;
}
body > #root .userBox:nth-child(n+4) {
  margin-top: 2%;
}
body > #root .userBox:hover {
  border-color: #C0C0C0;
}
body > #root .userBox:hover > .userData > img {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root .userBox > .userData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root .userBox > .userData {
    flex: 0 0 auto !important;
  }
}
body > #root .userBox > .userData > img {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  opacity: 0.6;
  filter: alpha(opacity=60);
  /* For IE8 and earlier */
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root .userBox > .userData > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 75%;
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  body > #root .userBox > .userData > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root .userBox > .userData > .infos > h3 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 0 5px;
  width: 95%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: #181818;
}
body > #root .userBox > .userData > .infos > .mail {
  font-size: 1em;
}
body > #root .userBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
@media screen and (max-width: 767px) {
  body > #root .userBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .userBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 15px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .userBox > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .userBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root .userBox > .buttons > button:hover {
  color: #FFF;
}
body > #root .userBox > .buttons > button:hover.editUser {
  background-color: #e69500;
}
body > #root .userBox > .buttons > button:hover.deleteUser {
  background-color: #e60000;
}
body > #root .userBox > .buttons > button > span {
  margin-left: 10px;
}
